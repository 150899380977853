export enum blockEnum {
  HEADING1 = 'heading_1',
  HEADING2 = 'heading_2',
  HEADING3 = 'heading_3',
  PARAGRAPH = 'paragraph',
  TOGGLE_LIST = 'toggle',
  DOTS_LIST = 'bulleted_list_item',
  ENUM_LIST = 'numbered_list_item',
  CHECK_LIST = 'to_do',
  TITLE = 'title',
  VIDEO = 'video',
  IMAGE = 'image',
  EMBED = 'embed',
  FILE = 'file',
  PDF = 'pdf',
  BOOKMARK = 'bookmark',
  CALLOUT = 'callout',
  QUOTE = 'quote',
  DIVIDER = 'divider',
  CODE = 'code',
  SYNCED_BLOCK = 'synced_block',
  TABLE_OF_CONTENTS = 'table_of_contents',
  TABLE = 'table',
  TABLE_ROW = 'table_row',
}

export const UNSUPPORTED_TYPE = 'unsupported'
